var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"loading":_vm.loading,"disabled":_vm.loading}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"8","align-self":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"fab":"","dark":"","x-small":"","color":"grey darken-3","to":{
                name: _vm.page_route,
              }}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-arrow-left')}})],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Atrás')}})]),_c('span',{staticClass:"text-h6 ml-1",domProps:{"textContent":_vm._s(_vm.$route.meta.title)}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","md":"4"}},[(_vm.login.permissions.insured_categories.update)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","dark":"","x-small":"","color":"info","to":{ name: (_vm.page_route + ".update"), params: { id: _vm.id } }}},on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,false,2513840978)},[_c('span',{domProps:{"textContent":_vm._s('Editar')}})]):_vm._e()],1)],1)],1),(_vm.data)?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('h2',{staticClass:"text-caption",domProps:{"textContent":_vm._s('GENERAL')}})]),_c('v-divider'),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"4"}},[_c('ViewData',{attrs:{"label":"Nombre","value":_vm.data.name}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"elevation":"1","dense":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"text-caption",domProps:{"textContent":_vm._s('REGISTRO')}})]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"6"}},[_c('ViewData',{attrs:{"label":"Creación","value":_vm.data.created_by.email,"subvalue":_vm.data.created_at}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","md":"6"}},[_c('ViewData',{attrs:{"label":"U. actualización","value":_vm.data.updated_by.email,"subvalue":_vm.data.updated_at}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.login.permissions.insured_categories.delete)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","dark":"","x-small":"","loading":_vm.loading,"color":"error"},on:{"click":function($event){$event.preventDefault();return _vm.HandleDesactivate.apply(null, arguments)}}},on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,false,1474562440)},[_c('span',{domProps:{"textContent":_vm._s('Desactivar')}})]):_vm._e()],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }